<template>
  <div class="page-container" v-if="loading">
    <div
      class="container-main-block"
      :style="tab === 0 ? `height: calc(100vh - 64px);` : 'height: 100vh'"
    >
      <div class="main-block-title pl-16 pr-16">
        <template v-if="item.lastName || item.firstName || item.middleName">
          {{ item.lastName }} {{ item.firstName }} {{ item.middleName }}
        </template>
        <template
          v-else-if="
            !item.lastName && !item.firstName && !item.middleName && item.email
          "
        >
          {{ item.email }}
        </template>
        <template
          v-else-if="
            !item.lastName &&
            !item.firstName &&
            !item.middleName &&
            !item.email &&
            item.phoneNumber
          "
        >
          {{ item.phoneNumber | VMask("+# (###) ### ##-##") }}
        </template>
      </div>
      <v-tabs
        class="pl-16 pr-16"
        v-model="tab"
        background-color="var(--app-grey-1)"
        color="var(--app-blue)"
      >
        <v-tabs-slider color="var(--app-blue)"></v-tabs-slider>
        <v-tab>
          <v-icon class="mr-2" size="18">mdi-information</v-icon>
          <div class="tab-text">Информация</div>
        </v-tab>
        <v-tab disabled>
          <v-icon class="mr-2" size="18">mdi-eye</v-icon>
          <div class="tab-text">Доступные курсы</div>
        </v-tab>
        <v-tab>
          <v-icon class="mr-2" size="18">mdi-school</v-icon>
          <div class="tab-text">Назначенное обучение</div>
        </v-tab>
      </v-tabs>
      <v-divider style="margin: 0 64px"></v-divider>
      <div class="main-block-items">
        <v-tabs-items v-model="tab">
          <v-tab-item :value="0">
            <UserInformation
              ref="userInformation"
              :item="item"
              :tags="tags"
              :groups="groups"
            />
          </v-tab-item>
          <v-tab-item :value="1"> <UserInformation /> </v-tab-item>
          <v-tab-item :value="2"><UserAssignment></UserAssignment> </v-tab-item>
        </v-tabs-items>
      </div>
    </div>
    <div class="container-bottom-block" v-if="tab === 0">
      <div>
        <v-btn depressed @click="getItem" class="button-white-black mr-4">
          Отменить изменения
        </v-btn>
        <v-btn depressed class="button-blue" @click="sendItem">
          Cохранить изменения
        </v-btn>
      </div>
      <div>
        <v-btn depressed class="button-white-red" @click="dialogDelete = true">
          Удалить профиль
        </v-btn>
      </div>
    </div>
    <DialogConfirm
      v-model="dialogDelete"
      titleText="Удаление пользователя"
      :mainText="`Вы уверены что хотите удалить  ${
        item
          ? `${item.lastName} ${item.firstName} ${item.middleName}`
          : 'сотрудника'
      }?`"
      @click:delete="deleteEmployee"
    >
    </DialogConfirm>
  </div>
</template>
<script>
import UserInformation from "@/views/Users/UserInformation";
import UserAssignment from "@/views/Users/UserAssignment";

import DialogConfirm from "@/components/main/dialog/DialogConfirm";
export default {
  components: {
    UserInformation,
    DialogConfirm,
    UserAssignment,
  },
  data: () => ({
    item: {},
    tags: [],
    groups: [],
    loading: false,
    tab: 0,
    dialogDelete: false,
  }),
  methods: {
    async getItem() {
      await this.$axios
        .get(this.$store.getters.apiAdminPanelV4 + "/user", {
          headers: {
            authorization: this.$store.getters.adminPanelHeaderAuth,
          },
          params: {
            id: this.$route.params.id,
          },
        })
        .then((res) => {
          this.item = res.data;
        })
        .catch((err) => {
          this.loading = false;
          this.$store.commit("errorSet", true);
          this.$store.commit("errorTextSet", err);
        });
    },
    async getTags() {
      await this.$axios
        .get(this.$store.getters.apiAdminPanelV4 + "/tag/list", {
          headers: {
            authorization: this.$store.getters.adminPanelHeaderAuth,
          },
        })
        .then((res) => {
          this.tags = res.data;
        })
        .catch((err) => {
          this.$store.commit("errorSet", true);
          this.$store.commit("errorTextSet", err);
        });
    },
    async getGroups() {
      await this.$axios
        .get(this.$store.getters.apiAdminPanelV4 + "/group/list", {
          headers: {
            authorization: this.$store.getters.adminPanelHeaderAuth,
          },
        })
        .then((res) => {
          this.groups = res.data.map((i) => {
            return i.group;
          });
        })
        .catch((err) => {
          this.$store.commit("errorSet", true);
          this.$store.commit("errorTextSet", err);
        });
    },
    getAllValues() {
      Promise.all([this.getItem(), this.getTags(), this.getGroups()]).then(
        () => {
          this.loading = true;
        }
      );
    },
    async sendItem() {
      // this.$refs.userInformation.dialogAddTag = true;
      const item = await this.$refs.userInformation.sendItem();
      if (item) {
        let formData = new FormData();
        formData.append("id", item.user.id);
        formData.append("firstName", item.user.firstName.trim());
        formData.append("middleName", item.user.middleName.trim());
        formData.append("lastName", item.user.lastName.trim());
        formData.append("phoneNumber", item.user.phoneNumber);
        formData.append("email", item.user.email);
        formData.append("photo", item.user.photo);
        formData.append("groupsIds", JSON.stringify(item.groupsIds));
        formData.append("tagValues", JSON.stringify(item.tags));
        formData.append("defaultAuth", item.user.defaultAuth);
        this.$axios
          .post(this.$store.getters.apiAdminPanelV4 + "/user", formData, {
            headers: {
              authorization: this.$store.getters.adminPanelHeaderAuth,
            },
          })
          .then(() => {
            this.$store.commit("successSet", true);
            this.$store.commit(
              "successTextSet",
              "Измененения успешно сохранены"
            );
            // this.sendUserToSmartGroup();
            this.getAllValues();
          })
          .catch((err) => {
            this.$store.commit("errorSet", true);
            this.$store.commit("errorTextSet", err);
          });
      }
    },
    sendUserToSmartGroup() {
      this.$axios
        .post(
          this.$store.getters.apiAdminPanelV4 + "/group/smart",
          {
            userId: this.$route.params.id,
          },
          {
            headers: {
              authorization: this.$store.getters.adminPanelHeaderAuth,
            },
          }
        )
        .then(() => {})
        .catch((err) => {
          this.$store.commit("errorSet", true);
          this.$store.commit("errorTextSet", err);
        });
    },
    deleteEmployee() {
      this.$axios
        .delete(this.$store.getters.apiAdminPanelV4 + "/user", {
          headers: {
            authorization: this.$store.getters.adminPanelHeaderAuth,
          },
          params: {
            id: this.$route.params.id,
          },
        })
        .then(() => {
          this.$router.push({ name: "Users" });
        })
        .catch((err) => {
          this.$store.commit("errorSet", true);
          this.$store.commit("errorTextSet", err);
        });
    },
  },
  created() {
    this.getAllValues();
  },
};
</script>
<style lang="scss" scoped>
.page-container {
  height: 100%;
  max-height: 100%;
  display: flex;
  flex-direction: column;

  .container-main-block {
    height: calc(100vh - 64px);
    display: flex;
    flex-direction: column;

    .main-block-title {
      margin-top: 40px;
      margin-bottom: 20px;
      font-size: 28px;
      font-weight: 600;
      color: var(--app-black);
    }
    .main-block-items {
      height: 100%;
      overflow: auto;
    }
  }
  .container-bottom-block {
    height: 64px;
    background: var(--app-grey-2);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 64px;
  }
}
</style>