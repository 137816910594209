<template>
  <div class="block-items-user-setting" v-if="loading">
    <div class="setting-block">
      <div class="setting-block-box">
        <div class="block-box-title">
          <div class="box-title-text">Информация</div>
        </div>
        <v-form ref="formInformation" class="block-box-items">
          <ImageInput v-model="user.photo" label="Фото профиля" />
          <TextInput v-model="user.lastName" label="Фамилия" valid />
          <TextInput v-model="user.firstName" label="Имя" valid />
          <TextInput v-model="user.middleName" label="Отчество" />
          <TextInput
            v-model="user.email"
            :errorMessages="mailAlreadyExists"
            label="Электронная почта"
            type="mail"
            valid
          ></TextInput>
          <PhoneInput
            v-model="user.phoneNumber"
            :errorMessages="phoneAlreadyExists"
            label="Телефон"
          ></PhoneInput>
          <v-switch
            label="Установить код авторизации: 0000"
            v-model="user.defaultAuth"
            style="margin-top: 0"
          ></v-switch>
        </v-form>
      </div>
      <div class="setting-block-box">
        <div class="block-box-title">
          <div class="box-title-text">Теги</div>
          <v-btn
            :disabled="tagsNotUsed.length == 0"
            depressed
            class="button-white-blue"
            @click="openDialogTag"
            >Добавить тег</v-btn
          >
        </div>
        <div class="block-box-items">
          <div class="d-flex" v-for="(tag, index) in tagsUser" :key="index">
            <!-- {{ tag }} -->
            <SelectInput
              v-if="tag.tagType == 'value'"
              :items="tag.values"
              :label="tag.name"
              v-model="tag.userSelected"
              itemText="value"
              itemValue="id"
              :clearable="false"
            />

            <DateInput
              v-else-if="tag.tagType == 'date'"
              :label="tag.name"
              v-model="tag.userSelected"
            />

            <div
              class="ml-4 mt-4"
              style="cursor: pointer"
              @click="deleteTag(tag)"
            >
              <v-icon>mdi-close</v-icon>
            </div>
          </div>
        </div>

        <Dialog
          v-model="dialogAddTag"
          titleText="Выберите теги который вы хотите добавить пользователю"
        >
          <template v-slot:blockMain>
            <div class="dialog-tag-table">
              <Table :items="tagsNotUsed" :headers="tagsHeaders" headerFixed>
                <template v-slot:[`item.tagType`]="{ item }">
                  <div v-if="item.tagType == 'value'">Тег по значению</div>
                  <div v-else-if="item.tagType == 'date'">Тег по дате</div>
                </template>
                <template v-slot:[`item.action`]="{ item }">
                  <CheckBox
                    v-model="item.selected"
                    color="var(--app-blue)"
                    @change="selectedTagMethod($event, item)"
                  ></CheckBox>
                </template>
              </Table>
            </div>
          </template>
          <template v-slot:blockFooter>
            <div class="d-flex justify-end" style="width: 100%">
              <v-btn
                @click="addUserTag"
                :disabled="selectedTags.length === 0"
                depressed
                class="button-blue"
              >
                Добавить
              </v-btn>
            </div>
          </template>
        </Dialog>
      </div>
    </div>
    <div class="setting-block">
      <div class="setting-block-box">
        <div class="block-box-title">
          <div class="box-title-text">Группы</div>
          <v-btn
            depressed
            class="button-white-blue"
            @click="openDialogGroup"
            :disabled="groupsNotUsed.length == 0"
            >Добавить в группу</v-btn
          >
        </div>
        <div
          class="block-box-items"
          v-if="user.groups && user.groups.length > 0"
        >
          <div
            v-for="(group, index) in user.groups"
            :key="index"
            class="box-items-list-item"
          >
            <div class="list-item-text">
              <span>
                {{ group.title }}
              </span>

              <v-icon v-if="group.isSmart" class="ml-1" size="16"
                >mdi-sync-circle</v-icon
              >
            </div>
            <div @click="deleteGroupUser(group)" class="list-item-icon">
              <v-icon>mdi-close</v-icon>
            </div>
          </div>
        </div>
      </div>
      <Dialog
        v-model="dialogAddGroup"
        titleText="Выберите группы в которые вы хотите добавить пользователя"
      >
        <template v-slot:blockMain>
          <div class="dialog-tag-table">
            <Table :items="groupsNotUsed" :headers="groupsHeaders" headerFixed>
              <template v-slot:[`item.action`]="{ item }">
                <CheckBox
                  v-model="item.selected"
                  color="var(--app-blue)"
                  @change="selectedGroupsMethod($event, item)"
                ></CheckBox>
              </template>
            </Table>
          </div>
        </template>
        <template v-slot:blockFooter>
          <div class="d-flex justify-end" style="width: 100%">
            <v-btn
              @click="addUserGroups"
              :disabled="selectedGroups.length === 0"
              depressed
              class="button-blue"
            >
              Добавить
            </v-btn>
          </div>
        </template>
      </Dialog>
      <div class="setting-block-box">
        <div class="block-box-title">
          <div class="box-title-text">Данные</div>
        </div>
        <div class="block-box-items">
          <div class="box-items-info">
            <div class="info-title">ID профиля</div>
            <div class="info-value">
              {{ user.id }}
            </div>
          </div>
          <div class="box-items-info">
            <div class="info-title">Дата создания</div>
            <div class="info-value">
              {{ user.dateCreated | moment("DD.MM.YYYY") }}
            </div>
          </div>
          <div class="box-items-info">
            <div class="info-title">Дата последнего редактирования</div>
            <div class="info-value">
              {{ user.dateUpdated | moment("DD.MM.YYYY") }}
            </div>
          </div>
          <div class="d-flex justify-space-between">
            <v-btn @click="resetPass" depressed class="button-white-blue">
              Сгенерировать новый пароль
            </v-btn>
            <Dialog
              v-model="dialogPass"
              titleText="Пароль"
              @click:success="copyPass"
              :buttonsFooter="{
                success: {
                  title: 'Скопировать пароль',
                },
              }"
            >
              <template v-slot:[`blockMain`]>
                <div class="mb-8">
                  Скопируйте и сохраните пароль, при утере пароля вы можете его
                  только сбросить.
                </div>
                <div
                  class="d-flex align-center justify-center"
                  style="font-size: 24px; font-weight: 500"
                >
                  {{ password }}
                </div>
              </template>
            </Dialog>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ImageInput from "@/components/main/inputs/ImageInput";
import TextInput from "@/components/main/inputs/TextInput";
import PhoneInput from "@/components/main/inputs/PhoneInput.vue";
import SelectInput from "@/components/main/inputs/SelectInput";
import CheckBox from "@/components/main/inputs/CheckBox";
import DateInput from "@/components/main/inputs/DateInput";
export default {
  components: {
    ImageInput,
    TextInput,
    PhoneInput,
    SelectInput,
    CheckBox,
    DateInput,
  },
  props: {
    item: {
      type: Object,
      default() {
        return {};
      },
    },
    tags: {
      type: Array,
      default() {
        return [];
      },
    },
    groups: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data: () => ({
    loading: false,
    user: { phoneNumber: "", email: "" },
    mailAlreadyExists: "",
    phoneAlreadyExists: "",
    tagsUser: [],
    tagsNotUsed: [],
    groupsNotUsed: [],
    selectedGroups: [],
    selectedTags: [],
    dialogAddTag: false,
    dialogAddGroup: false,
    tagsHeaders: [
      {
        text: "",
        value: "action",
      },
      {
        text: "Название тега",
        value: "name",
      },
      {
        text: "Тип тега",
        value: "tagType",
      },
    ],
    groupsHeaders: [
      {
        text: "",
        value: "action",
      },
      {
        text: "Название группы",
        value: "title",
      },
    ],
    dialogPass: false,
    password: "",
  }),
  methods: {
    resetPass() {
      this.$axios
        .put(
          this.$store.getters.apiAdminPanelV4 + "/user/password",
          {
            userId: this.$route.params.id,
          },
          {
            headers: {
              authorization: this.$store.getters.adminPanelHeaderAuth,
            },
          }
        )
        .then((res) => {
          this.password = res.data.password;
          this.dialogPass = true;
        })
        .catch((err) => {
          this.$store.commit("errorSet", true);
          this.$store.commit("errorTextSet", err);
        });
    },
    copyPass() {
      var dummy = document.createElement("input");
      document.body.appendChild(dummy);
      dummy.value = this.password;
      dummy.select();
      document.execCommand("copy");
      document.body.removeChild(dummy);
      this.$store.commit("successSet", true);
      this.$store.commit(
        "successTextSet",
        "Пароль успешно скопирован в буфер обмена"
      );
    },

    setTags() {
      const arrConcat = this.user.tagValues?.concat(this.user.tagDates);
      this.tags.forEach((i) => {
        if (arrConcat && arrConcat.length > 0) {
          arrConcat.forEach((element) => {
            if (element.tagId == i.id) {
              i.userSelected =
                i.tagType == "value"
                  ? element.id
                  : i.tagType == "date"
                  ? element.date
                  : parseInt(element.value);

              this.tagsUser.push({ ...i });
            }
          });
        }
      });
      this.findNeededTags();
    },
    findNeededTags() {
      this.tagsNotUsed = [];
      this.tags.forEach((element) => {
        if (!this.tagsUser.find((i) => i.id == element.id)) {
          this.tagsNotUsed.push({ ...element, selected: false });
        }
      });
    },
    selectedTagMethod(bool, item) {
      if (bool) {
        this.selectedTags.push({ ...item });
      } else {
        this.selectedTags = this.selectedTags.filter((i) => i.id != item.id);
      }
    },
    openDialogTag() {
      this.selectedTags = [];
      this.dialogAddTag = true;
    },

    addUserTag() {
      this.selectedTags.forEach((element) => {
        this.tagsUser.push({ ...element, userSelected: "" });
      });
      this.dialogAddTag = false;
      this.findNeededTags();
    },
    deleteTag(tag) {
      this.tagsUser = this.tagsUser.filter((i) => i.id != tag.id);
      this.findNeededTags();
    },
    updateUser() {
      this.user = JSON.parse(JSON.stringify(this.item));
      this.tagsUser = [];
      this.tags.length > 0 ? this.setTags() : "";
      this.groups.length > 0 ? this.findNeededGroups() : "";
      this.loading = true;
    },
    findNeededGroups() {
      this.groupsNotUsed = [];
      if (this.user.groups && this.user.groups.length > 0) {
        this.groups.forEach((i) => {
          if (!i.isSmart) {
            if (!this.user.groups.find((el) => el.id == i.id)) {
              delete i.users;
              this.groupsNotUsed.push({ ...i, selected: false });
            }
          }
        });
      } else {
        this.groupsNotUsed = this.groups
          .map((i) => {
            if (!i.isSmart) {
              delete i.users;
              i.selected = false;
              return i;
            }
            return "";
          })
          .filter((i) => i);
      }
    },
    selectedGroupsMethod(bool, item) {
      if (bool) {
        this.selectedGroups.push({ ...item });
      } else {
        this.selectedGroups = this.selectedGroups.filter(
          (i) => i.id != item.id
        );
      }
    },
    addUserGroups() {
      if (!this.user.groups) {
        this.user.groups = [];
      }
      this.selectedGroups.forEach((element) => {
        delete element.selected;
        this.user.groups.push({ ...element });
      });
      this.findNeededGroups();
      this.dialogAddGroup = false;
    },
    openDialogGroup() {
      this.selectedGroups = [];
      this.dialogAddGroup = true;
    },
    deleteGroupUser(item) {
      this.user.groups = this.user.groups.filter((i) => i.id != item.id);
      this.findNeededGroups();
    },
    async checkDuplicate(data, type) {
      await this.$axios
        .get(this.$store.getters.apiAdminPanelV4 + "/user/login/check", {
          headers: {
            authorization: this.$store.getters.adminPanelHeaderAuth,
          },
          params: {
            data: data,
          },
        })
        .then(() => {
          if (type == "phone") {
            this.phoneAlreadyExists = "";
          } else {
            this.mailAlreadyExists = "";
          }
        })
        .catch((err) => {
          if (err.response.data.result == "user_already_exists") {
            if (type == "phone") {
              this.phoneAlreadyExists = "Такой номер уже зарегистрирован";
            } else {
              this.mailAlreadyExists = "Такая почта уже зарегистрирована";
            }
          } else {
            this.$store.commit("errorSet", true);
            this.$store.commit("errorTextSet", err);
          }
        });
    },
    async sendItem() {
      if (this.$refs.formInformation.validate()) {
        this.mailAlreadyExists = "";
        if (this.user.email && this.user.email.length > 0) {
          if (this.item.email !== this.user.email) {
            if (this.validateEmail(this.user.email)) {
              await this.checkDuplicate(this.user.email, "email");
            } else {
              this.mailAlreadyExists = "";
            }
          }
        }
        if (this.user.phoneNumber.length === 11) {
          if (this.item.phoneNumber !== this.user.phoneNumber) {
            await this.checkDuplicate(this.user.phoneNumber, "phone");
          } else {
            this.phoneAlreadyExists = "";
          }
        }
        if (this.mailAlreadyExists == "" && this.phoneAlreadyExists == "") {
          const newObj = {
            user: this.user,
            groupsIds: this.user.groups?.map((i) => {
              return i.id;
            }),
            tags: this.tagsUser?.map((i) => {
              return { tagId: i.id, data: i.userSelected, tagType: i.tagType };
            }),
          };
          return newObj;
        }
      }
    },
  },
  mounted() {},
  created() {
    this.updateUser();
  },
  beforeDestroy() {},
  watch: {
    item() {
      this.updateUser();
    },
  },
};
</script>
<style lang="scss" scoped>
.block-items-user-setting {
  background: var(--app-grey-1);
  //   overflow: auto;
  height: 100%;
  padding: 32px 64px;
  display: flex;
  justify-content: space-between;
  .setting-block {
    .setting-block-box {
      .block-box-title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;
        .box-title-text {
          font-size: 22px;
          font-weight: 600;
          color: var(--app-black);
        }
      }
      .block-box-items {
        .box-items-list-item {
          width: 100%;
          min-height: 50px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          background: var(--app-white);
          border-radius: 6px;
          margin-bottom: 20px;
          padding: 16px 12px;
          .list-item-text {
            display: flex;
            align-items: center;
            font-size: 16px;
            color: var(--app-black);
          }
          .list-item-icon {
            cursor: pointer;
          }
        }
        .box-items-info {
          margin-bottom: 24px;
          .info-title {
            font-size: 12px;
            text-transform: uppercase;
            color: var(--app-grey-3);
          }
          .info-value {
            font-size: 14px;
            color: var(--app-black);
          }
        }
      }
    }
  }
  .setting-block:first-child {
    width: 60%;
    padding-right: 48px;
  }
  .setting-block:last-child {
    width: 40%;
    padding-left: 48px;
  }
  .dialog-tag-table {
    height: 400px;
  }
}
</style>
