import { render, staticRenderFns } from "./UserInformation.vue?vue&type=template&id=5b92977c&scoped=true"
import script from "./UserInformation.vue?vue&type=script&lang=js"
export * from "./UserInformation.vue?vue&type=script&lang=js"
import style0 from "./UserInformation.vue?vue&type=style&index=0&id=5b92977c&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5b92977c",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSwitch } from 'vuetify/lib/components/VSwitch';
installComponents(component, {VBtn,VForm,VIcon,VSwitch})
