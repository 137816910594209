<template>
  <div class="user-assignment-block" v-if="loading">
    <div class="block-statistics" v-if="statistic">
      <div class="statistics-block">
        <div class="statistics-block-title">Всего курсов</div>
        <div class="statistics-block-value"></div>
      </div>
      <div class="statistics-block">
        <div class="statistics-block-title">Всего экзаменов</div>
        <div class="statistics-block-value"></div>
      </div>
      <div class="statistics-block">
        <div class="statistics-block-title">Прохождение</div>
        <div class="statistics-block-value"></div>
      </div>
      <div class="statistics-block">
        <div class="statistics-block-title">Средний результат</div>
        <!-- <div class="statistics-block-value" v-if="statistics.averagePercent">
          {{ statistics.averagePercent }} %
        </div>
        <div class="statistics-block-value" v-else>0 %</div> -->
      </div>
    </div>
    <div class="block-table">
      <Table :items="items" :headers="headers" headerFixed> 

      </Table>
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  props: {
    item: {
      type: Object,
      default() {
        return {};
      },
    },
    statistic: {
      type: Object,
      default() {
        return null;
      },
    },
  },
  data: () => ({
    loading: false,
    items: [],
    headers: [
 
      {
        text: "Тип",
        value: "type",
      },
      {
        text: "Название",
        value: "name",
      },
      {
        text: "Дата назначения",
        value: "",
        // dateAssignment
      },
       {
        text: "Ограничения",
        value: "",
      },
       {
        text: "Осталось времени",
        value: "",
      },
      {
        text: "Прохождение %",
        value: "progress",
      },
      {
        text: "Результат %",
        value: "result",
      },
    ],
  }),
  methods: {
 
    async getItems() {
      await this.$axios
        .get(this.$store.getters.apiAdminPanelV4 + "/user/assignment", {
          headers: {
            authorization: this.$store.getters.adminPanelHeaderAuth,
          },
          params: {
            id: this.$route.params.id,
          },
        })
        .then((res) => {
          this.items = res.data;
          this.loading = true;
        })
        .catch((err) => {
          this.$store.commit("errorSet", true);
          this.$store.commit("errorTextSet", err);
        });
    },
  },
  created() {
    this.getItems();
  },
  computed: {},
  mounted() {},

  watch: {},
};
</script>
<style lang="scss" scoped>
.user-assignment-block {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  background: var(--app-grey-1);
  padding: 32px 0px;
  .block-statistics {
    display: flex;
    justify-content: space-between;
    padding: 0px 64px 32px 64px;

    .statistics-block {
      padding: 24px;
      background: var(--app-white);
      border-radius: 6px;
      margin: 0px 8px;
      width: 100%;
      // min-width: 385px;
      .statistics-block-title {
        font-size: 18px;
        font-weight: 600;
        color: var(--app-black);
        margin-bottom: 4px;
      }
      .statistics-block-value {
        font-size: 16px;
        color: var(--app-grey-3);
      }
    }
    .statistics-block:first-child {
      margin-left: 0px;
    }
    .statistics-block:last-child {
      margin-right: 0px;
    }
  }
  .block-table {
    padding: 0px 64px;
    height: calc(100vh - 220px);
  }
}
</style>